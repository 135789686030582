.dynamicLink {
  max-width: 500px;
}

.podcastEpisodeModal {
  height: 400px;
  overflow: scroll;
  margin-top: 2rem;
  width: 500px;
}

.contentList {
  height: 400px;
  overflow: scroll;
  margin-top: 2rem;
}
