@import "./variables.scss";
.headerd {
  height: 160px !important;
  width: 100%;
  background-color: #ffffff !important;
  padding: 0 !important;
}

.alignH {
  display: flex;
  align-items: center;
  position: absolute;
  top: 30px;
  padding-left: 30px;
  width: 80%;
  pointer-events: none;
}

.headerLogout {
  position: absolute;
  right: 2%;
  top: 2%;
}

.bgg {
  background-color: red;
}
